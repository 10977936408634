<template>
<!-- eslint-disable max-len -->
  <div class="row w-100 m-auto">
    <div class="col mb-2">
      <count-info-card
        :title="'Upcoming Events'"
        :number="upcomingFunctions"
        :loading="isCountInfoLoading"
        v-on:click="actionCountCardClick"
      />
    </div>
    <div class="col mb-2">
      <count-info-card
        :title="'Upcoming Experiences'"
        :number="upcomingExperiences"
        :loading="isCountInfoLoading"
        v-on:click="actionCountCardClick"
      />
    </div>
    <div class="col mb-2">
      <count-info-card
        :title="'All Upcoming Activities'"
        :number="upcomingEvents"
        :loading="isCountInfoLoading"
        v-on:click="actionCountCardClick"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CountInfoCard from '@/components/CountInfoCard.vue';

export default {
  components: {
    CountInfoCard,
  },

  computed: {
    ...mapGetters('dashboard', {
      upcomingExperiences: 'upcomingExperiences',
      upcomingEvents: 'upcomingEvents',
      upcomingFunctions: 'upcomingFunctions',
    }),

    isCountInfoLoading() {
      return this.$store.getters['dashboard/isLoading']('countInfo');
    },
  },

  methods: {
    actionCountCardClick() {
      console.debug('count card clicked');
      this.$store.dispatch('dashboard/getDashboardData');
    },
  },

};
</script>

<style>

</style>
