<template>
  <div class="card">
    <div class="card-body">
      <h6 class="card-title d-flex justify-content-between align-items-center">
        {{ title }}

        <template v-if="loading">
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </template>
        <template v-else>
          <button class="btn btn-sm bg-light text-secondary"
            :disabled="loading"
            @click="onClick"
          >
            <span class="material-icons-outlined">
            refresh
            </span>
          </button>
        </template>
      </h6>
      <h1 class="card-text py-2 text-center font-weight-bold text-secondary">
        {{ number }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
